import HorizontalLine from "../HorizontalLine/HorizontalLine";
import "./ComponentFooter.scss";
import { BrandLogo } from "../../../Images";
import { Link } from "react-router-dom";
import {
    FaPhoneAlt,
    FaFacebookF,
    FaLinkedinIn,
    FaYoutube,
    FaMapMarkerAlt,
    FaEnvelope,
    FaTwitter,
} from "react-icons/fa";

const ComponentFooter = () => {
    const locationOld =
        "Peter House, Oxford St, Manchester M1 5AN, United Kingdom";
    const location = "71-75 Shelton Street, Covent Garden, London";
    const location2 = `265 Franklin St. Ste 1702,    Boston Ma, 02110, United States`;
    const locationLux = `Av. des Hauts-Fourneaux, 4362 Esch-Belval Esch-sur-Alzette, Luxembourg`;
    const email = "info@myelinh.com";
    const email2 = "info@myelinh.com";
    const telephone = "+49 152 125 977 03";

    const twitter = `https://twitter.com/myelin__h`;
    const linkedIn = `https://www.linkedin.com/company/myelinh/`;
    const youTube = `https://www.youtube.com/channel/UCPJzppsXHK7BZFixyv6AHvw`;
    const mapLink = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9628746826543!2d-0.12501170000000003!3d51.51389710000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccc13bd265%3A0xce7119851b3ea21f!2sShelton%20St%2C%20London%20WC2H%2C%20UK!5e0!3m2!1sen!2sbd!4v1689350753163!5m2!1sen!2sbd`;
    // const mapLink = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2374.518857915545!2d-2.246140384156614!3d53.47705848000665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bb1ea05c82c13%3A0x6c6b7df5cd395470!2sPeter%20House%2C%20Oxford%20St%2C%20Manchester%20M1%205AN%2C%20UK!5e0!3m2!1sen!2sbd!4v1671624805233!5m2!1sen!2sbd`
    const mapUSA = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.3428808258996!2d-71.05598742423662!3d42.356531435325024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37086d21ed265%3A0x2b6519560942a239!2s265%20Franklin%20St%20%231702%2C%20Boston%2C%20MA%2002110%2C%20USA!5e0!3m2!1sen!2ses!4v1722001126868!5m2!1sen!2ses`;
    return (
        <footer className="bg-primary text-white">
            <div className="container py-5">
                <h2 className="h2 text-center text-white section-heading">
                    Contact Us
                </h2>
                <HorizontalLine center="true" className="mb-3" />
                <div className="row d-flex justify-content-center">
                    <div className="col-md-7 text-center mb-5 mb-md-0 text-center text-md-left">
                        <div className="w-100 d-flex justify-content-center">
                            <Link to="/">
                                <img
                                    src={BrandLogo}
                                    alt=""
                                    className="img-fluid site-icon mb-3"
                                />
                            </Link>
                        </div>
                        <p className="text-white text-center w-100">
                            71-75 Shelton Street, Covent Garden, London, United
                            Kingdom, WC2H 9JQ
                        </p>
                        <p className="text-white text-center w-100">
                          {location2}
                        </p>
                        <p className="text-white text-center w-100">
                          {locationLux}
                        </p>
                        <div className="text-center d-flex justify-content-center text-md-left mt-4">
                            {/* <a className="profile-contact-icon-container mr-2" href={fb} target="_blank" rel="noopener noreferrer">
                                <div className="profile-contact-icons"><FaFacebookF /> </div>
                            </a> */}
                            {/* <a className="profile-contact-icon-container mr-2" href={twitter} target="_blank" rel="noopener noreferrer">
                                <div className="profile-contact-icons"><FaTwitter /> </div>
                            </a> */}
                            <a
                                className="profile-contact-icon-container mr-2"
                                href={linkedIn}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="profile-contact-icons">
                                    <FaLinkedinIn />{" "}
                                </div>
                            </a>
                            <a
                                className="profile-contact-icon-container "
                                href={youTube}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div className="profile-contact-icons">
                                    <FaYoutube />{" "}
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-md-4 mt-4">
                                <iframe
                                    title="Myelin-H Location"
                                    className="w-100 map"
                                    src={mapLink}
                                    height="300"
                                    frameborder="0"
                                    style={{ border: "0" }}
                                    allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"
                                ></iframe>
                            </div>
                            <div className="col-md-4 mt-4">
                                <iframe
                                    title="Myelin-H Location"
                                    className="w-100 map"
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d82913.61996509194!2d5.949167!3d49.502438!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eacad49ef04f7d%3A0x8599a1646a7921b9!2sTECHNOPORT%20SA%20%E2%80%93%20BELVAL%2C%209%20Avenue%20des%20Hauts-Fourneaux%2C%204362%20Esch%20an%20der%20Alzette%2C%20Luxembourg!5e0!3m2!1sen!2sus!4v1611471815357!5m2!1sen!2sus"
                                    height="300"
                                    frameborder="0"
                                    style={{ border: "0" }}
                                    allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"
                                ></iframe>
                            </div>
                            <div className="col-md-4 mt-4">
                                <iframe
                                    title="Myelin-H Location"
                                    className="w-100 map"
                                    src={mapUSA}
                                    height="300"
                                    frameborder="0"
                                    style={{ border: "0" }}
                                    allowfullscreen=""
                                    aria-hidden="false"
                                    tabindex="0"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 mt-4">
                        <iframe title="Myelin-H Location" className="w-100 map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.908007861393!2d-0.1257728842297435!3d51.5149036796363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604ccab37652b%3A0x22220c3f07824033!2sShop%2071%2C%20ALG%20ID%20Cards%20t%2Fa%20The%20Lanyard%2C%2075%20Shelton%20St%2C%20London%20WC2H%209JQ%2C%20UK!5e0!3m2!1sen!2sbd!4v1665031732237!5m2!1sen!2sbd" height="300" frameborder="0" style={{ border: "0" }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>


                    </div> */}
                </div>
            </div>
            {/* <hr style={{ border: "1px solid grey" }} /> */}

            <section
                style={{ borderTop: "1px solid #44444488" }}
                className="bg-primary text-center text-white py-3"
            >
               <div className="container">
               <address
                    className="mt-3 mb-1 text-center "
                    style={{ verticalAlign: "center" }}
                >
                    <span className="icon-wrapper">
                        {" "}
                        <FaMapMarkerAlt />{" "}
                    </span>{" "}
                    {location} | <FaMapMarkerAlt /> {location2} | <FaMapMarkerAlt /> {locationLux} |
                    <br className="d-block d-md-none" />
                    <FaEnvelope /> <a href={"mailto:" + email}>{email} </a>
                </address>
               </div>

                {/* <address className="mt-3 mb-1 d-block d-md-none text-center" style={{ verticalAlign: "center" }} >
                    <span className="icon-wrapper"><FaMapMarkerAlt /></span>  {location}  <br />
                    <FaEnvelope /> <a href={"mailto:" + email}>{email} </a><br />
                </address> */}

                <p className="mt- mb-">&copy; 2024 | myelinh.com</p>
            </section>
        </footer>
    );
};

export default ComponentFooter;
