import HorizontalLine from "../../../Components/common/HorizontalLine/HorizontalLine";
import {
    FaPhoneAlt,
    FaFacebookF,
    FaLinkedinIn,
    FaYoutube,
    FaMapMarkerAlt,
    FaEnvelope,
    FaTwitter,
} from "react-icons/fa";
import "./ContactForm.scss";

import OfficeImg from "./myelinh-office.jpg";

const ContactForm = () => {
    const location =
        "71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ";
    const location2 = "265 Franklin St. Ste 1702,    Boston Ma, 02110, United States";
    const email = "info@myelinh.com";
    const locationLux = `Av. des Hauts-Fourneaux, 4362 Esch-Belval Esch-sur-Alzette, Luxembourg.`;

    const email2 = "info@myelinh.com";
    const telephone = "+49 152 125 977 03";

    const twitter = `https://twitter.com/MyelinH`;
    const linkedIn = `https://www.linkedin.com/company/myelinh/`;
    const youTube = `https://www.youtube.com/channel/UCPJzppsXHK7BZFixyv6AHvw`;

    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 offset-md-1 mb-5 mb-md-0">
                            <h3 className="text-center font-bold mb-4">
                                Send your message
                            </h3>

                            <form>
                                <div className="row ">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            placeholder="Your name"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            placeholder="Your email"
                                            type="email"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="col-md-12 form-group">
                                        <label htmlFor="message">
                                            Your message
                                        </label>
                                        <textarea
                                            name=""
                                            id=""
                                            cols="30"
                                            rows="8"
                                            className="form-control"
                                        ></textarea>
                                    </div>
                                    <div className="col-12 text-center text-md-left">
                                        <button className="btn btn-dark custom-round">
                                            Submit Now
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-4 text-center"></div>
                        <div className="col-md-12 mt-5">
                            <h3 className="text-center font-weight-bold">
                                Other ways to contact
                            </h3>
                            <h3 className="text-center">
                                Call, e-mail, social network
                            </h3>

                            <div className="mt-4 text-center">
                                <h5 className="text-primary mt-2 mb-1 font-18">
                                    {" "}
                                    <span className="icon-wrapper">
                                        <FaMapMarkerAlt />
                                    </span>{" "}
                                    {location}{" "}  <br />
                                    

                                </h5>
                                <h5 className="text-primary mt-2 mb-1 font-18">
                                    {" "}
                                    <span className="icon-wrapper">
                                        <FaMapMarkerAlt />
                                    </span>{" "}
                                    {location2}{" "}  <br />
                                    

                                </h5>
                                <h5 className="text-primary mt-2 mb-1 font-18">
                                    {" "}
                                    <span className="icon-wrapper">
                                        <FaMapMarkerAlt />
                                    </span>{" "}
                                    {locationLux}{" "}  <br />
                                    

                                </h5>
                                <h5 className="text-primary mt-2 mb-1 font-18">
                                    {" "}
                                    <FaEnvelope />{" "}
                                    <a href={"mailto:" + email}>{email} </a>
                                </h5>
                            </div>
                            <div className="mt-4 text-center">
                                <a
                                    className="profile-contact-icon-container mr-2"
                                    href={linkedIn}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className="profile-contact-icons">
                                        <FaLinkedinIn />{" "}
                                    </div>
                                </a>
                                <a
                                    className="profile-contact-icon-container "
                                    href={youTube}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className="profile-contact-icons">
                                        <FaYoutube />{" "}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ContactForm;
